import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaFigma,
  FaSketch,
  FaFile,
  FaPalette,
  FaJira,
  FaGithub,
} from "react-icons/fa";
import { VscVscodeInsiders } from "react-icons/vsc";
const iconStyle = {
  fontSize: "48px", // Adjust the icon size as needed
};

const textStyle = {
  fontSize: "16px", // Set the desired font size for the text
  textAlign: "center", // Center the text
};
const Toolstack = () => {
  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaFigma />
          </p>
          <p style={textStyle}>Figma</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaSketch />
          </p>
          <p style={textStyle}>Sketch</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaPalette />
          </p>
          <p style={textStyle}>Adobe XD</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaFile />
          </p>
          <p style={textStyle}>Miro</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaJira />
          </p>
          <p style={textStyle}>Jira</p>
        </Col>

        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <VscVscodeInsiders />
          </p>
          <p style={textStyle}>VsCode</p>
        </Col>

        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaGithub />
          </p>
          <p style={textStyle}>Github</p>
        </Col>
      </Row>
    </>
  );
};

export default Toolstack;
