import React from "react";
import {
  FaRocket,
  FaPencilAlt,
  FaSitemap,
  FaPaintBrush,
  FaCss3,
  FaReact,
  FaHtml5,
} from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { RiTailwindCssFill } from "react-icons/ri";
import { GiMaterialsScience } from "react-icons/gi";
import { MdOutlineFiberNew } from "react-icons/md";
import { Col, Row } from "react-bootstrap";

const Skillstack = () => {
  const iconStyle = {
    fontSize: "48px", // Adjust the icon size as needed
  };
  const iconStyleNew = {
    fontSize: "22px",
    textAlign: "left",
    padding: 0,
    margin: 0, // Adjust the icon size as needed
  };

  const textStyle = {
    fontSize: "16px", // Set the desired font size for the text
    textAlign: "center",
  };

  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyleNew}>
            <MdOutlineFiberNew />
          </p>
          <p style={iconStyle}>
            <IoLogoJavascript />
          </p>
          <p style={textStyle}> JavaScript</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyleNew}>
            <MdOutlineFiberNew />
          </p>
          <p style={iconStyle}>
            <FaReact />
          </p>
          <p style={textStyle}> ReactJs</p>
        </Col>

        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaHtml5 /> {/* Relevant icon */}
          </p>
          <p style={textStyle}>HTML</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaCss3 /> {/* Relevant icon */}
          </p>
          <p style={textStyle}>CSS</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyleNew}>
            <MdOutlineFiberNew />
          </p>
          <p style={iconStyle}>
            <GiMaterialsScience />
          </p>
          <p style={textStyle}> Material UI</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyleNew}>
            <MdOutlineFiberNew />
          </p>
          <p style={iconStyle}>
            <RiTailwindCssFill />
          </p>
          <p style={textStyle}> Tailwind</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaPaintBrush /> {/* Relevant icon */}
          </p>
          <p style={textStyle}>UI/UX design</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaSitemap /> {/* Relevant icon */}
          </p>
          <p style={textStyle}>Information architecture</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaPencilAlt /> {/* Relevant icon */}
          </p>
          <p style={textStyle}>Wireframing</p>
        </Col>
        <Col xs={12} md={2} className="tech-icons">
          <p style={iconStyle}>
            <FaRocket />
          </p>
          <p style={textStyle}>Prototyping</p>
        </Col>
      </Row>
    </>
  );
};

export default Skillstack;
