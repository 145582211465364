import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";

const ProjectCard = (props) => {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title
          style={{
            color: "#F2613F",
            fontWeight: "bold",
            fontSize: "1.3em",
            margin: "10px",
          }}
        >
          {props.title}
        </Card.Title>
        <Card.Text
          style={{
            textAlign: "justify",
            color: "#eee",
            fontWeight: "medium",
            fontSize: "1em",
            margin: "10px",
          }}
        >
          {props.description}
        </Card.Text>

        {props.additionalText && (
          <Card.Text style={{ textAlign: "center" }}>
            {props.additionalText}
          </Card.Text>
        )}

        {!props.isBlog && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Demo"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};
export default ProjectCard;
