import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Affinidi from "./pages/Affinidi";
import Pickrr from "./pages/Pickrr";
import Footer from './components/Footer/Footer'
import Home from "./pages/Home";
import Skill from "./pages/Skillset";
import Project from "./pages/Projects";
import Navbar from "./components/Navbar/Navbar";
import Preloader from "./components/PreLoader";
import ScrollToTop from "./components/ScrollToTop";
import "./App.css";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Router>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/skillset" element={<Skill />} />
            <Route path="/project" element={<Project />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/Pickrr" element={<Pickrr />} />
            <Route path="/Affinidi" element={<Affinidi />} />
          </Routes>

        </div>
      </Router>

      <Footer />
    </>

  );
}

export default App;
