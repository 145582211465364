import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} xs={12} className="home-about-description">
            <p className="home-about-body">
              {" "}
              <b className="yellow"> Recently</b>, I have expanded my expertise
              by learning{" "}
              <b className="yellow">  ReactJS,  </b>. I am
              excited to apply my design skills and new front-end development
              knowledge to create seamless and engaging user interfaces.
            </p>
            <p className="home-about-body">
              <br />
              I am UI/UX designer with over 3 years of experience in e-commerce
              (Shipment Solutions) and IT services. Holding a Bachelor's degree
              in Computer Science and a Master's in Data Analytics, I have a
              deep understanding of both the technical and analytical aspects of
              design. I've worked with various clients, improving my ability to
              create easy-to-use and innovative user experiences. I love
              tackling new challenges and constantly learning new skills.
              <br />
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
